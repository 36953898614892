.banner{
    display: flex;
    align-items: center;
    position: relative;
    padding: 8em;
    max-width: 100vw;
    padding-top: 12em;
    
}

.banner .text{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.banner h1{
    font-size: 6em;
    font-weight: 800;
    line-height: 1.25em;
}

.banner h1 span{
    color: var(--accentcolor);
}

.buttons{
    display: flex;
    gap: 2em;
}

.buttons .book {
    padding: 0.5em 1.75em;
    font-weight: 700;
    font-size: 1.125em;
    background-color: var(--accentcolor);
    color: var(--white);
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border: none;
    transition: 0.25s ease-in-out;
    border: 2px solid var(--accentcolor);
}

.buttons .book:hover{
    background-color: transparent;
    border: 2px solid var(--accentcolor);
    color: black;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.buttons .learn{
    padding: 0.5em 1.75em;
    font-weight: 700;
    font-size: 1.125em;
    background-color: var(--darkcolor);
    color: var(--white);
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border: none;
    transition: 0.25s ease-in-out;
    border: 2px solid var(--darkcolor);
}

.buttons .learn:hover{
    background-color: transparent;
    border: 2px solid var(--darkcolor);
    color: black;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}


.banner-image{
    max-height: 20em;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.banner-background{
    position: absolute;
    right: 0;
    top: 0;
    height: 22em;
    z-index: -1;
    opacity: 0.4;
    mask-image: linear-gradient(to left, rgba(0, 0, 0, 1.0) 20%, transparent 80%);
    
}


/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .banner{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 2em;
        padding-top: 12em;
    }
    .banner .text{
        width: 100%;
    }
    .banner .text h1 {
        font-size: 3em;
    }
    .buttons {
        display: flex;
        flex-direction: column;
        gap: 2em;
        align-items: center;
        justify-content: center;
    }
    .buttons > *{
        width: 250px;
    }
    .banner-image{
        display: none;
    }
    .banner-background{
        display: none;
    }
}

@media only screen and (min-device-width : 481px) and (max-device-width : 750px){
    .banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 4em;
        padding-top: 12em;
    }
    .banner .text {
        width: 100%;
    }

    .banner .text h1 {
        font-size: 4em;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 2em;
        align-items: center;
        justify-content: center;
    }

    .buttons>* {
        width: 250px;
    }

    .banner-image {
        display: none;
    }

    .banner-background {
        display: none;
    }
}


/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 751px) and (max-device-width : 1024px) {
    .banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 8em;
        padding-top: 12em;
    }

    .banner .text {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .banner .text h1 {
        font-size: 4em;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 2em;
        align-items: center;
        justify-content: center;
    }

    .buttons>* {
        width: 250px;
    }

    .banner-image {
        display: none;
    }

    .banner-background {
        display: none;
    }
}

@media only screen and (min-device-width : 1025px) and (max-device-width : 1224px) {
    .banner{
        
        padding: 4em;
        padding-top: 10em;
    }

    .banner .text h1 {
        font-size: 4em;
    }

    .banner-image{
        max-height: 15em;
    }

    .banner-background{
        height: 18em;
    }
}

/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
    /* Styles */
}