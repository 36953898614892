*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Catamaran", sans-serif; 
    scroll-behavior: smooth;
}

:root{
    --darkcolor: #14213D;
    --accentcolor: #FCA311;
    --lightcolor: #E5E5E5;
    --white: #fff;
    --black: #000;
}

html{
    scroll-behavior: smooth;
}