.book-a-car{
    margin: 12em;
    padding: 4em 6em;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); 
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-bottom: 6em;
}

.book-a-car h1{
    color: var(--accentcolor);
    font-weight: 800;
    font-size: 2em;
}

.book-a-car form{
    display: flex;
    gap: 2em 1em;
    flex-wrap: wrap;
    justify-content: space-between;
}

.book-a-car p{
    font-weight: 700;
}

.book-a-car span {
    color: var(--accentcolor);
}

.book-a-car .input{
    width: 250px;
    height: 35px;
    font-size: 1em;
    text-indent: 5px;
}

.book-a-car button{
    background-color: transparent;
    color: black;
    font-weight: 700;
    font-size: 1.125em;
    border: 2px solid var(--accentcolor);
    transition: 0.25s ease-in-out;
    width: 250px;
    height: 60px;
}

.book-a-car button:hover{
    background-color: var(--accentcolor);
    color: var(--white);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.book-a-car .icon{
    height: 1em;
    aspect-ratio: 1;
}

.book-a-car .text{
    display: flex;
    align-items: center;
    gap: 0.25em;
}




/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .book-a-car{
        margin: 2em;
        padding: 4em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .book-a-car form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        gap: 2em;
    }

    .book-a-car .text{
        margin-bottom: 5px;
    }
}

@media only screen and (min-device-width : 480px) and (max-device-width : 750px) {
    .book-a-car {
        margin: 6em;
        padding: 4em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .book-a-car form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        gap: 2em;
    }

    .book-a-car .text {
        margin-bottom: 5px;
    }
}


/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 750px) and (max-device-width : 1024px) {
    .book-a-car {
        margin: 8em;
        padding: 4em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .book-a-car form {
        display: flex;
        
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 2em;
    }

    .book-a-car .text {
        margin-bottom: 10px;
    }
}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1224px) {
    .book-a-car {
        margin: 4em;
        padding: 4em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .book-a-car form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 2em;
    }

    .book-a-car .text {
        margin-bottom: 10px;
    }
}