.carCard {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0.5em;
    width: 300px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    background-color: var(--white);
}

.carCard .icon {
    height: 1em;
    aspect-ratio: 1;
}

.carCard .location {
    display: flex;
    align-items: center;
    gap: 0.25em;
    color: var(--accentcolor);

}

.carCard .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.carCard .name {
    font-size: 1.125em;
    font-weight: 600;
}

.carCard .price {
    font-size: 1.5em;
    font-weight: 700;
}

.carCard .price span {
    color: var(--accentcolor);
}

.carCard button {
    padding: 0.5em;
    font-weight: 700;
    font-size: 1em;
    border: 1px solid var(--accentcolor);
    background: transparent;
    color: black;
    cursor: pointer;
    transition: 0.25s ease-in-out;
}

.carCard button:hover {
    background-color: var(--accentcolor);
    color: var(--white);
}