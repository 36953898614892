.fleet{
    padding: 12em;
    margin-top: 12em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5em;
    padding-bottom: 0;
}

.fleet .title{
    font-size: 3em;
    font-weight: 700;
    line-height: 1.25em;
}

.fleet .subtext {
    color: var(--accentcolor);
    font-weight: 600;
    line-height: 1.25em;
}

.fleet .vehicle-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 2em 4em;
    margin-top: 6em;
    width: 100%;
}




/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .fleet{
        padding: 2em;
        margin-top: 0em;
        padding-top: 8em;
        text-align: center;
        padding-bottom: 0;
    }
    .fleet .vehicle-list{
        flex-wrap: nowrap;
        flex-direction: column;
        gap: 2em;
        text-align: left;
    }
}

@media only screen and (min-device-width : 480px) and (max-device-width : 900px) {
    .fleet {
        padding: 4em;
        margin-top: 0em;
        padding-top: 8em;
        padding-bottom: 0;
    } 
    .fleet .vehicle-list {
        flex-wrap: nowrap;
        flex-direction: column;
        gap: 2em;
        text-align: left;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 901px) and (max-device-width : 1070px) {
    .fleet {
        padding: 6em;
        margin-top: 0em;
        padding-top: 8em;
        padding-bottom: 0;
    }
}

@media only screen and (min-device-width : 1071px) and (max-device-width : 1224px) {
    .fleet {
        padding: 12em;
        margin-top: 0em;
        padding-top: 8em;
        padding-bottom: 0;
    }
}