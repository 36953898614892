.service-card {
    position: relative;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2em;
    
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    color: black;
    width: 20em;
    gap: 1em;
}

.coloredband{
    height: 0.25em;
    background-color: var(--accentcolor);
    width: 100%;
    position: absolute;
    top: 0;
}

.service-card img {
    width: 6em;
    height: 6em;
}

.service-card h1 {
    font-weight: 800;
    line-height: 1.25em;
    font-size: 1.75em;
}