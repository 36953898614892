.service-container{
    padding-top: 5em;
}

.service{
    position: relative;
    background-color: var(--darkcolor);
    color: var(--white);
    padding: 6em 12em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1.5em;
    padding-bottom: 12em;
}

.service .title{
    font-size: 3em;
    font-weight: 700;
    line-height: 1.25em;
    width: 10em;
    z-index: 1;
}

.service .subtext{
    color: var(--accentcolor);
    font-weight: 600;
    line-height: 1.25em;
    width: 10em;
    z-index: 1;
}

.service .cards{
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 6em;
    bottom: -55%;
}



/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .service .cards{
        position: static;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 2em;
        margin-top: 4em;
        
    }
    .service{
        padding: 6em 2em;
    }
    .service .title{
        width: auto;
    }
}

@media only screen and (min-device-width : 480px) and (max-device-width : 750px) {
    .service .cards {
        position: static;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 2em;
        margin-top: 4em;

    }

    .service {
        padding: 6em 2em;
    }

    .service .title {
        width: auto;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 750px) and (max-device-width : 1024px) {
    .service .cards {
        position: static;
        flex-wrap: nowrap;
        max-width: 100vw;
        margin-top: 4em;
        gap: 10px;
        
    }

    .service {
        padding: 6em 2em;
    }

    .service .title {
        width: auto;
    }
}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1224px) {
    .service .cards {
        position: static;
        flex-wrap: nowrap;
        max-width: 100vw;
        margin-top: 4em;
        gap: 10px;

    }

    .service {
        padding: 6em 2em;
    }

    .service .title {
        width: auto;
    }
}