.get-in-touch{
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: center;
    justify-content: center;
    margin: 8em 12em;
    margin-top: 12em;
    margin-bottom: 0;
}

.get-in-touch .title {
    font-size: 3em;
    font-weight: 700;
    line-height: 1.25em;
}

.get-in-touch .subtext {
    color: var(--accentcolor);
    font-weight: 600;
    line-height: 1.25em;
}

.get-in-touch .form-and-info{
    background-color: var(--lightcolor);
    display: flex;
    width: 100%;
    margin-top: 4em;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.get-in-touch .info{
    color: var(--white);
    background-color: var(--darkcolor);
    padding: 2em 1em;
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.get-in-touch .info h1{
    font-size: 1.25em;
    font-weight: 700;
}

.get-in-touch form{
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 2em;
    justify-content: center;
    width: 100%;
}

.get-in-touch form div{
    display: flex;
    gap: 2em;
    align-items: center;
}

.get-in-touch input {
    height: 40px;
    font-size: 1em;
    text-indent: 5px;
    width: 100%;
}

.get-in-touch textarea{
    height: 12em;
    font-size: 1em;
    width: 100%;
    padding: 5px;
    resize: none;
}

.get-in-touch button {
    background-color: transparent;
    color: var(--white);
    background-color: var(--darkcolor);
    border: none;
    transition: 0.25s ease-in-out;
    font-weight: 700;
    font-size: 1em;
    width: 150px;
    height: 50px;
}

.get-in-touch button:hover {
    background-color: var(--accentcolor);
    color: var(--white);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.get-in-touch .button-and-message{
    display: flex;
    gap: 2em;
    flex-direction: row;
    align-items: center;
}

.get-in-touch .status{
    font-weight: 600;
    color: #77DD77;
}



/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .get-in-touch{
        margin: 2em;
        margin-top: 8em;
        margin-bottom: 0;
    }
    
    .get-in-touch .form-and-info{
        flex-direction: column;
    }

    .get-in-touch .info{
        width: 100%;
    }

    .get-in-touch form{
        padding: 0.75em;
    }

    .get-in-touch form div {
        flex-direction: column;
        gap: 1em;
        width: 100%;
    }

    .get-in-touch .button-and-message{
        gap: 1em;
    }
}

@media only screen and (min-device-width : 481px) and (max-device-width : 750px) {
    .get-in-touch {
        margin: 4em;
        margin-top: 8em;
        margin-bottom: 0;
    }

    .get-in-touch .form-and-info {
        flex-direction: column;
    }

    .get-in-touch .info {
        width: 100%;
    }

    .get-in-touch form {
        padding: 1em;
    }

    .get-in-touch form div {
        gap: 1em; 
        width: 100%;
        flex-direction: column;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 751px) and (max-device-width : 1024px) {
    .get-in-touch {
        margin: 6em;
        margin-top: 8em;
        margin-bottom: 0;
    }

    .get-in-touch .form-and-info {
        flex-direction: column;
    }

    .get-in-touch .info {
        width: 100%;
    }

    .get-in-touch form div {
        gap: 2em;
        width: 100%;
    }
}

@media only screen and (min-device-width : 1025px) and (max-device-width : 1224px) {
    .get-in-touch {
        margin: 8em;
        margin-top: 8em;
        margin-bottom: 0;
    }
}