.brands{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4em;
    padding: 0 8em;
    
    padding-top: 4em;
}

.car-logo{
    height: 6em;
    opacity: 25%;
}




/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .brands{
        display: none;
    }
}

@media only screen and (min-device-width : 480px) and (max-device-width : 750px) {
    .brands {
        display: none;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 750px) and (max-device-width : 1024px) {
    .brands {
        display: none;
    }
}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1224px) {
    .brands {
        padding: 0 4em;
    }
}