body{
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* overflow-x: hidden; */
}

.app{
    display: flex;
    flex-direction: column;
}