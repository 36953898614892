.navbar{
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em 8em;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    z-index: 10;
    position: fixed;
    background-color: white;
}

.navbar img{
    width: 10em;
}

.navbar ul{
    list-style: none;
    display: flex;
    gap: 1.5em;
    align-items: center;
}

.navbar ul li a{
    font-weight: 700;
    color: var(--black);
    text-decoration: none;
    transition: 0.25s;
}

.navbar ul li a:hover{
    color: var(--accentcolor);
}

.register{
    padding: 0.5em 1.75em;
    font-weight: 700;
    background-color: var(--darkcolor);
    color: var(--white);
    cursor: pointer;
    
    border: none;
    transition: 0.25s ease-in-out;
}

.register:hover{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: var(--accentcolor);
}




.hamburger-button {
    display: none;
    cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
    width: 35px;
    height: 3px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

/* Rotate first bar */
.toggle .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
}

/* Fade out the second bar */
.toggle .bar2 {
    opacity: 0;
}

/* Rotate last bar */
.toggle .bar3 {
    transform: translate(0, -7px) rotate(45deg);
}





/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .hamburger-button {
        display: inline-block;
        cursor: pointer;
    }

    .navbar{
        padding: 2em;
    }

    .nav-links{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        position: fixed;
        padding: 2em;
        top: 100px;
        left: 100%;
        background-color: white;
        width: 100vw;
        height: 100vh;
        transition: 0.5s;
    }

    .nav-auth{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 25em;
        left: 100%;
        width: 100vw;
        height: 100vh;
        transition: 0.5s;
    }

    .active{
        transform: translate(-100%);
    }
}

@media only screen and (min-device-width : 481px) and (max-device-width : 750px) {
    .hamburger-button {
        display: inline-block;
        cursor: pointer;
    }

    .navbar {
        padding: 2em;
    }

    .nav-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        position: fixed;
        padding: 2em;
        top: 100px;
        left: 100%;
        background-color: white;
        width: 100vw;
        height: 100vh;
        transition: 0.5s;
    }

    .nav-auth {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 25em;
        left: 100%;
        width: 100vw;
        height: 100vh;
        transition: 0.5s;
    }

    .active {
        transform: translate(-100%);
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 751px) and (max-device-width : 1024px) {
    .hamburger-button {
        display: inline-block;
        cursor: pointer;
    }

    .navbar {
        padding: 2em;
    }

    .nav-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        position: fixed;
        padding: 2em;
        top: 100px;
        left: 100%;
        background-color: white;
        width: 100vw;
        height: 100vh;
        transition: 0.5s;
    }

    .nav-auth {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 25em;
        left: 100%;
        width: 100vw;
        height: 100vh;
        transition: 0.5s;
    }

    .active {
        transform: translate(-100%);
    }
}

@media only screen and (min-device-width : 1025px) and (max-device-width : 1224px) {
    .navbar {
        padding: 2em 4em;
    }
}